import {
  IInvitePartnerDTO,
  ICompanyNameVerificationDTO,
  ISupplyChainManagementDTO,
  ILineChartDTO,
  ITotalInvitationDTO,
  IReInvitationDTO,
  ISingleInvitationRequest,
} from "@/models/SupplyChainManagement";
import { IPagination } from "@/models/Pagination";
import { ErrorResponse } from "@/services/axios/error";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { SupplyChainManagementAPI } from "@/services/SupplyChainManagement";

const name = "SupplyChainModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class SupplyChainModule extends VuexModule {
  private errorInvitePartner = {} as ErrorResponse;
  private errorVerificationCompanyName = {} as ErrorResponse;
  private errorGetSupplyChainData = {} as ErrorResponse;
  private errorGetProgressChart = {} as ErrorResponse;
  private errorGetLineChart = {} as ErrorResponse;
  private errorGetBarChart = {} as ErrorResponse;
  private errorCheckRole = {} as ErrorResponse;
  private errorGetTotalInvitation = {} as ErrorResponse;
  private errorVerifyInvitedFile = {} as ErrorResponse;
  private errorSendInvitation = {} as ErrorResponse;
  private isLoadingSupplyChainManagement = true;
  private isLoadingGetProgressChart = false;
  private isLoadingGetLineChart = false;
  private isLoadingGetBarChart = false;
  private isLoadingCheckRole = true;
  private isLoadingTotalInvitation = false;
  private isLoadingDownloadTemplate = false;
  private isLoadingVerifyFile = false;
  private isLoadingSendInvitation = false;
  private pagination = {} as IPagination;
  private dataGetSupplyChain = [] as ISupplyChainManagementDTO[];
  private dataGetProgressChart = [] as ISupplyChainManagementDTO[];
  private dataGetLineChart = [] as ILineChartDTO[];
  private dataGetBarChart = [] as ILineChartDTO[];
  private dataCheckRole = false as boolean;
  private dataTotalInvitation = {} as ITotalInvitationDTO;

  get hasInvitePartnerErrorMsg() {
    return !isEmpty(this.errorInvitePartner);
  }

  get isLoadingSupplyChainManagementGetter() {
    return this.isLoadingSupplyChainManagement;
  }

  get paginationGetter() {
    return this.pagination;
  }

  get listDataGetSupplyChain() {
    return this.dataGetSupplyChain;
  }

  get listDataGetProgressChartGetter() {
    return this.dataGetProgressChart;
  }
  get isLoadingGetProgressChartGetter() {
    return this.isLoadingGetProgressChart;
  }

  get listDataGetLineChartGetter() {
    return this.dataGetLineChart;
  }
  get isLoadingGetLineChartGetter() {
    return this.isLoadingGetLineChart;
  }

  get listDataGetBarChartGetter() {
    return this.dataGetBarChart;
  }
  get isLoadingGetBarChartGetter() {
    return this.isLoadingGetBarChart;
  }

  get getCheckRoleGetter() {
    return this.dataCheckRole;
  }
  get isLoadingCheckRoleGetter() {
    return this.isLoadingCheckRole;
  }

  get getTotalInvitationGetter() {
    return this.dataTotalInvitation;
  }
  get isLoadingTotalInvitationGetter() {
    return this.isLoadingTotalInvitation;
  }

  get isLoadingDownloadTemplateGetter() {
    return this.isLoadingDownloadTemplate;
  }

  get isLoadingVerifyFileGetter() {
    return this.isLoadingVerifyFile;
  }

  get isLoadingSendInvitationGetter() {
    return this.isLoadingSendInvitation;
  }

  @Mutation
  invitePartnerSuccess() {
    ElMessage.success("Successfully invited your partner!");
    this.errorInvitePartner = {} as ErrorResponse;
  }
  @Mutation
  invitePartnerFailure(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorInvitePartner = error;
  }
  @Action({ rawError: true })
  async invitePartner(payload: ISingleInvitationRequest) {
    const servicesSupplyChainsAPI = new SupplyChainManagementAPI();
    const { error } = await servicesSupplyChainsAPI.invitePartner(payload);
    if (error) {
      this.invitePartnerFailure(error);
    } else {
      this.invitePartnerSuccess();
    }
  }

  @Mutation
  verificationCompanyNameSuccess() {
    this.errorVerificationCompanyName = {} as ErrorResponse;
  }
  @Mutation
  verificationCompanyNameFailure(error: ErrorResponse) {
    this.errorVerificationCompanyName = error.message;
  }
  @Action({ rawError: true })
  async verificationCompanyName(payload: ICompanyNameVerificationDTO) {
    const servicesUserAPI = new SupplyChainManagementAPI();
    const { error, data } = await servicesUserAPI.companyNameVerification(
      payload
    );

    if (error) {
      this.verificationCompanyNameFailure(error);
      return error.message;
    } else {
      this.verificationCompanyNameSuccess();
      if (data) {
        return "Company Name is existed";
      }
    }
  }

  @Mutation
  setLoadingSupplyChainManagement(loading: boolean) {
    this.isLoadingSupplyChainManagement = loading;
  }
  @Mutation
  getSupplyChainDataSuccess(data: any) {
    this.errorGetSupplyChainData = {} as ErrorResponse;
    const { Collection, ...rest } = data;
    this.dataGetSupplyChain = Collection;
    this.pagination = rest;
    this.isLoadingSupplyChainManagement = false;
  }
  @Mutation
  getSupplyChainDataFailure(error: ErrorResponse) {
    this.errorGetSupplyChainData = error.message;
    ElMessage.error(error.message);
    this.isLoadingSupplyChainManagement = false;
  }
  @Action({ rawError: true })
  async getSupplyChainData(payload: any) {
    this.setLoadingSupplyChainManagement(true);
    const servicesUserAPI = new SupplyChainManagementAPI();
    const { error, data } = await servicesUserAPI.getSupplyChainsManagement(
      payload.year,
      payload.pagination
    );
    if (error) {
      this.getSupplyChainDataFailure(error);
    } else {
      this.getSupplyChainDataSuccess(data);
    }
  }

  @Mutation
  setLoadingGetProgressChart(loading: boolean) {
    this.isLoadingGetProgressChart = loading;
  }
  @Mutation
  getProgressChartSuccess(data: any) {
    this.errorGetProgressChart = {} as ErrorResponse;
    this.dataGetProgressChart = data;
    this.isLoadingGetProgressChart = false;
  }
  @Mutation
  getProgressChartFailure(error: ErrorResponse) {
    this.errorGetProgressChart = error.message;
    ElMessage.error(error.message);
    this.isLoadingGetProgressChart = false;
  }
  @Action({ rawError: true })
  async getProgressChart(payload: any) {
    this.setLoadingGetProgressChart(true);
    const servicesUserAPI = new SupplyChainManagementAPI();
    const { error, data } = await servicesUserAPI.getProgressChartData(payload);

    if (error) {
      this.getProgressChartFailure(error);
    } else {
      this.getProgressChartSuccess(data);
    }
  }

  @Mutation
  setLoadingGetLineChart(loading: boolean) {
    this.isLoadingGetLineChart = loading;
  }
  @Mutation
  getLineChartSuccess(data: any) {
    this.errorGetLineChart = {} as ErrorResponse;

    const newArr: any = [];
    data.forEach((item: any) => {
      newArr.push([item.Month, item.Value]);
    });
    this.dataGetLineChart = newArr;
    this.isLoadingGetLineChart = false;
  }
  @Mutation
  getLineChartFailure(error: ErrorResponse) {
    this.errorGetLineChart = error.message;
    ElMessage.error(error.message);
    this.isLoadingGetLineChart = false;
  }
  @Action({ rawError: true })
  async getLineChart() {
    this.setLoadingGetLineChart(true);
    const servicesUserAPI = new SupplyChainManagementAPI();
    const { error, data } = await servicesUserAPI.getLineChartData();

    if (error) {
      this.getLineChartFailure(error);
    } else {
      this.getLineChartSuccess(data);
    }
  }

  @Mutation
  setLoadingGetBarChart(loading: boolean) {
    this.isLoadingGetBarChart = loading;
  }
  @Mutation
  getBarChartSuccess(data: any) {
    this.errorGetBarChart = {} as ErrorResponse;

    const transKey: any = {
      BelowPercentage: "10%",
      TenPercentage: "20%",
      TwentyPercentage: "30%",
      ThirtyPercentage: "40%",
      FourtyPercentage: "50%",
      FifTyPercentage: "60%",
      SixtyPercentage: "70%",
      SeventyPercentage: "80%",
      EightyPercentage: "90%",
      NinetyPercentage: "100%",
    };

    const newArr: any = [];
    for (const [key, value] of Object.entries(data)) {
      newArr.push([transKey[key], value]);
    }
    this.dataGetBarChart = newArr;
    this.isLoadingGetBarChart = false;
  }
  @Mutation
  getBarChartFailure(error: ErrorResponse) {
    this.errorGetLineChart = error.message;
    ElMessage.error(error.message);
    this.isLoadingGetBarChart = false;
  }
  @Action({ rawError: true })
  async getBarChart(payload: any) {
    this.setLoadingGetBarChart(true);
    const servicesUserAPI = new SupplyChainManagementAPI();
    const { error, data } = await servicesUserAPI.getBarChartData(payload);

    if (error) {
      this.getBarChartFailure(error);
    } else {
      this.getBarChartSuccess(data);
    }
  }

  @Mutation
  setLoadingCheckRole(loading: boolean) {
    this.isLoadingCheckRole = loading;
  }
  @Mutation
  getCheckRoleFailure(error: ErrorResponse) {
    this.errorCheckRole = error.message;
    this.isLoadingCheckRole = false;
  }
  @Mutation
  checkRoleSuccess(data: any) {
    this.dataCheckRole = data;
    this.isLoadingCheckRole = false;
  }
  @Action({ rawError: true })
  async checkRole() {
    this.setLoadingCheckRole(true);
    const servicesSupplyChainAPI = new SupplyChainManagementAPI();
    const { error, data } = await servicesSupplyChainAPI.checkRole();

    if (error) {
      this.getCheckRoleFailure(error);
    } else {
      this.checkRoleSuccess(data);
    }
  }

  @Mutation
  setLoadingTotalInvitation(loading: boolean) {
    this.isLoadingTotalInvitation = loading;
  }
  @Mutation
  getDataTotalInvitationFailure(error: ErrorResponse) {
    this.errorGetTotalInvitation = error.message;
    this.isLoadingTotalInvitation = false;
  }
  @Mutation
  getDataTotalInvitationSuccess(data: any) {
    this.dataTotalInvitation = data;
    this.errorGetTotalInvitation = {} as ErrorResponse;
    this.isLoadingTotalInvitation = false;
  }
  @Action({ rawError: true })
  async getTotalInvitation() {
    this.setLoadingTotalInvitation(true);
    const servicesSupplyChainAPI = new SupplyChainManagementAPI();
    const { error, data } =
      await servicesSupplyChainAPI.getTotalInvitationData();

    if (error) {
      this.getDataTotalInvitationFailure(error);
    } else {
      this.getDataTotalInvitationSuccess(data);
    }
  }

  @Mutation
  setLoadingVerifyFile(loading: boolean) {
    this.isLoadingVerifyFile = loading;
  }
  @Action({ rawError: true })
  async verifyInvitedFile(payload: any) {
    this.setLoadingVerifyFile(true);
    const servicesSupplyChainAPI = new SupplyChainManagementAPI();
    const { error, data } = await servicesSupplyChainAPI.verifyInvitedFile(
      payload
    );

    if (error) {
      this.setLoadingVerifyFile(false);
      return error;
    } else {
      this.setLoadingVerifyFile(false);
      return data;
    }
  }

  @Mutation
  setLoadingDownloadInvitationTemplate(loading: boolean) {
    this.isLoadingDownloadTemplate = loading;
  }
  @Mutation
  downloadInvitationTemplateFailure(error: ErrorResponse) {
    this.errorVerifyInvitedFile = error.message;
    ElMessage.error("Download failed!");
    this.isLoadingDownloadTemplate = false;
  }
  @Action({ rawError: true })
  async downloadInvitationTemplate() {
    this.setLoadingDownloadInvitationTemplate(true);
    const servicesSupplyChainAPI = new SupplyChainManagementAPI();
    const { error, data } =
      await servicesSupplyChainAPI.downloadInvitationTemplate();

    if (error) {
      this.downloadInvitationTemplateFailure(error);
    } else {
      this.setLoadingDownloadInvitationTemplate(false);
      return data;
    }
  }

  @Mutation
  setLoadingSendInvitation(loading: boolean) {
    this.isLoadingSendInvitation = loading;
  }
  @Mutation
  sendInvitationFailure(error: ErrorResponse) {
    this.errorSendInvitation = error.message;
    ElMessage.error("Send invitation failed!");
    this.isLoadingSendInvitation = false;
  }
  @Mutation
  sendInvitationSuccess() {
    this.errorSendInvitation = {} as ErrorResponse;
    ElMessage.success("Send invitation success!");
    this.isLoadingSendInvitation = false;
  }
  @Action({ rawError: true })
  async sendInvitation(payload: any) {
    this.setLoadingSendInvitation(true);
    const servicesSupplyChainAPI = new SupplyChainManagementAPI();
    const { error } = await servicesSupplyChainAPI.sendInvitation(payload);

    if (error) {
      this.sendInvitationFailure(error);
    } else {
      this.sendInvitationSuccess();
    }
  }

  @Mutation
  reSendInvitationSuccess(data: string) {
    const findIndex = this.dataGetSupplyChain.findIndex(
      (x) => x.TenantSupplyChainId == data
    );
    if (findIndex !== -1) {
      this.dataGetSupplyChain[findIndex].isReSend = true;
    }
    this.errorSendInvitation = {} as ErrorResponse;
    ElMessage.success("Send invitation success!");
    this.isLoadingSendInvitation = false;
  }
  @Action({ rawError: true })
  async resendInvitations(payload: IReInvitationDTO) {
    this.setLoadingSendInvitation(true);
    const servicesUserAPI = new SupplyChainManagementAPI();
    const { error } = await servicesUserAPI.resendInvitation(payload);

    if (error) {
      this.sendInvitationFailure(error);
    } else {
      this.reSendInvitationSuccess(payload.TenantSupplyChainId);
    }
  }
}

export default getModule(SupplyChainModule);
